<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow">
      <template #right>
        <el-button class="float-r" size="small" @click="handleShowMessage"
          ><i class="el-icon-bell"></i> 通知</el-button
        >
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addRow"
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          >新增</el-button
        >
        <el-button
          size="small"
          class="mt-r"
          @click="showExcelImportDialog = true"
          >EXCEL导入</el-button
        >

        <el-dropdown
          size="small"
          class="mt-r"
          @command="handleExcelCommand"
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ]) && false
          "
        >
          <el-button size="small" :disabled="exporting"
            >{{ exporting ? "正在导出" : "导出EXCEL" }}
            <i
              :class="[
                exporting
                  ? 'el-icon-loading'
                  : 'el-icon-arrow-down el-icon--right',
              ]"
            ></i
          ></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="currentPage" :disabled="exporting"
              >导出当前页</el-dropdown-item
            >
            <el-dropdown-item command="all" :disabled="exporting"
              >导出全部</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </PageHeader>
    <!-- <section class="page-header base-shadow">
      <h3 class="header-title">
        <span class="title-icon">
          <svg-icon :iconClass="$route.meta.iconSvg"></svg-icon>
        </span>
        {{ $route.meta.title }}
      </h3>
    </section> -->
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <basic-table
      hiddenClear
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <!-- 临时使用 -->
        <el-dropdown
          size="small"
          class="mt-r"
          @command="handleExcelCommand"
          v-if="tempShowExport"
        >
          <el-button size="small" :disabled="exporting"
            >{{ exporting ? "正在导出" : "导出EXCEL" }}
            <i
              :class="[
                exporting
                  ? 'el-icon-loading'
                  : 'el-icon-arrow-down el-icon--right',
              ]"
            ></i
          ></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="currentPage" :disabled="exporting"
              >导出当前页</el-dropdown-item
            >
            <el-dropdown-item command="all" :disabled="exporting"
              >导出全部</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">
              {{ scope.row.createdAt | date_time }}
            </span>
            <span v-else-if="column.prop === 'ownerExpiredAt'">{{
              scope.row.ownerExpiredAt | date
            }}</span>
            <span v-else-if="column.prop === 'mainContactName'">{{
              scope.row.mainContact !== null ? scope.row.mainContact.name : ""
            }}</span>
            <span v-else-if="column.prop === 'mainContactPhoneNo'">
              <ClientReapetTable
                v-if="scope.row.mainContact !== null"
                :id="scope.row.id"
                :userPhone="scope.row.mainContact.phoneNo"
              />
              <span v-else>\</span>
            </span>
            <span v-else-if="column.prop === 'star'">
              <i
                class="el-icon-star-off"
                v-if="!scope.row.star"
                style="font-size: 19px; cursor: pointer"
                @click="handleStartClient(scope.row)"
              ></i>
              <i
                class="el-icon-star-on text-start"
                v-if="scope.row.star"
                style="font-size: 24px; cursor: pointer"
                @click="handleUnStartClient(scope.row)"
              ></i>
            </span>
            <span v-else-if="column.prop === 'name'">
              <span
                style="padding-left: 5px"
                title="点击查看详情"
                class="text-nav"
                @click="onShowDetail(scope.row)"
                >{{ scope.row.name }}</span
              >
            </span>
            <span v-else-if="column.prop === 'isClaim'">
              <CheckClaim :clientId="scope.row.id" />
            </span>

            <span v-else-if="column.prop === 'followUpStatusCount'">
              <ClientFollowUpRecordListItem :id="scope.row.id" />
            </span>

            <span v-else-if="column.prop === 'address.country'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.country
              }}
            </span>
            <span v-else-if="column.prop === 'address.province'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.province
              }}
            </span>
            <span v-else-if="column.prop === 'address.city'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.city }}
            </span>
            <span v-else-if="column.prop === 'address.region'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.region }}
            </span>
            <span v-else-if="column.prop === 'address.street'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.street }}
            </span>

            <span v-else-if="column.prop === 'tags'">
              <span v-if="scope.row.tags.length <= 0">/</span>
              <span v-else-if="scope.row.tags.length <= 1">{{
                scope.row.tags[0].name
              }}</span>
              <el-popover v-else placement="left" width="300" trigger="click">
                <el-tag
                  class="tag"
                  v-for="item in scope.row.tags"
                  :key="item.id"
                  type="info"
                  style="margin-right: 10px; margin-bottom: 10px"
                >
                  <i class="el-icon-paperclip"></i>
                  {{ item.name }}
                </el-tag>
                <el-button slot="reference" type="text" icon="el-icon-view"
                  >{{ scope.row.tags[0].name }}（{{
                    scope.row.tags.length
                  }}个）</el-button
                >
              </el-popover>
            </span>

            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
            </span>
            <span v-else-if="column.prop === 'isFetched'">
              <el-tag :type="scope.row.isFetched ? 'success' : 'danger'">{{
                scope.row.isFetched ? "是" : "否"
              }}</el-tag>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                checkPermission([
                  'CLIENT_ADMIN',
                  'CLIENT_GROUP_ADMIN',
                  'CLIENT_USER',
                ])
              "
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.disabled"
              @click.native.prevent="disableRow(scope.row, scope.$index)"
              type="text"
              size="small"
              :loading="scope.row.canDisableChecking"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "禁用" }}
            </el-button>
            <el-button
              class="text-success"
              v-if="scope.row.disabled"
              @click.native.prevent="enableRow(scope.row, scope.$index)"
              type="text"
              size="small"
              :loading="scope.row.canDisableChecking"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "启用" }}
            </el-button>
            <el-dropdown>
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" :hide-on-click="false">
                <el-dropdown-item
                  @click.native.prevent="
                    handleMessageAdd(scope.row, scope.$index)
                  "
                  >写提醒</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="!scope.row.star"
                  @click.native.prevent="
                    handleStartClient(scope.row, scope.$index)
                  "
                  >设为星标</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="scope.row.star"
                  @click.native.prevent="
                    handleUnStartClient(scope.row, scope.$index)
                  "
                  >取消星标</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native.prevent="
                    handleSignDialog(scope.row, scope.$index)
                  "
                  :disabled="scope.row.canUpdateSignChecking"
                  >{{
                    scope.row.canUpdateSignChecking
                      ? "权限检验中．．．"
                      : "设置签约"
                  }}</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="scope.row.canUpdateStageChecking"
                  @click.native.prevent="
                    handleStageDialog(scope.row, scope.$index)
                  "
                  >{{
                    scope.row.canUpdateStageChecking
                      ? "权限检验中．．．"
                      : "设置阶段"
                  }}</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native.prevent="
                    handleOwnerDialog(scope.row, scope.$index)
                  "
                  :disabled="scope.row.canUpdateOwnerChecking"
                  >{{
                    scope.row.canUpdateOwnerChecking
                      ? "权限检验中．．．"
                      : "设置负责人"
                  }}</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="
                    checkPermission([
                      'CLIENT_ADMIN',
                      'CLIENT_GROUP_ADMIN',
                      'CLIENT_USER',
                      'CLIENT_OBSERVER',
                    ])
                  "
                  @click.native.prevent="
                    onShowTagDialog(scope.row, scope.$index)
                  "
                  >设置标签</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          type="success"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleUndisableLoading"
          >{{
            multipleUndisableLoading ? multipleDisableLoadingText : "批量启用"
          }}
          {{ multipleSelected.length }}</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleDisableLoading"
          >{{
            multipleDisableLoading ? multipleDisableLoadingText : "批量禁用"
          }}
          {{ multipleSelected.length }}</el-button
        >
        <el-button
          type="success"
          plain
          size="small"
          @click="onMultipleStartClient(true)"
          :disabled="multipleSelected.length <= 0"
          :loading="toggleStartLoading"
          >批量设为星标 {{ multipleSelected.length }}</el-button
        >
        <el-button
          type="danger"
          size="small"
          plain
          @click="onMultipleStartClient(false)"
          :disabled="multipleSelected.length <= 0"
          :loading="toggleStartLoading"
          >批量取消星标 {{ multipleSelected.length }}</el-button
        >
        <el-button
          type="info"
          size="small"
          @click="showMultipleOwnerDialog = true"
          :disabled="multipleSelected.length <= 0"
          >批量设置负责人 {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
    <el-dialog
      title="设置签约客户"
      :visible.sync="showSingDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <ClientSignForm
        :clientId="currentRow.id"
        :signedAt="currentRow.signedAt"
        :signedUserId="currentRow.signedUserId"
        @cancel="onSignFormCancel"
        @success="onSignFormSuccess"
      />
    </el-dialog>
    <el-dialog
      :title="
        currentRow.id ? `设置【${currentRow.name}】的客户阶段` : '设置客户阶段'
      "
      :visible.sync="showStageDialog"
      append-to-body
      :close-on-click-modal="false"
      width="500px"
    >
      <SetStageForm
        :clientId="currentRow.id"
        :stageId="currentRow.stageId"
        @cancel="onStageFormCancel"
        @success="onStageFormSuccess"
      />
    </el-dialog>
    <el-dialog
      :title="
        currentRow.id
          ? `设置【${currentRow.name}】的客户负责人`
          : '设置客户负责人'
      "
      :visible.sync="showOwnerDialog"
      append-to-body
      :close-on-click-modal="false"
      width="500px"
    >
      <SetOwnerForm
        :clientId="currentRow.id"
        :ownerId="currentRow.ownerId"
        @cancel="onOwnerFormCancel"
        @success="onOwnerFormSuccess"
      />
    </el-dialog>
    <el-dialog
      title="批量设置客户负责人"
      :visible.sync="showMultipleOwnerDialog"
      append-to-body
      :close-on-click-modal="false"
      width="500px"
    >
      <MultipleSetOwnerForm
        :multipleSelected="multipleSelected"
        @cancel="onOwnerFormCancel"
        @success="onOwnerFormSuccess"
      />
    </el-dialog>
    <el-dialog
      title="EXCEL导入"
      :visible.sync="showExcelImportDialog"
      :close-on-click-modal="false"
      append-to-body
      width="90%"
    >
      <ImportByExcel
        @cancel="showExcelImportDialog = false"
        @success="onImportSuccess"
      />
    </el-dialog>
    <el-dialog
      :title="currentRow.name ? `设置【${currentRow.name}】的标签` : '设置标签'"
      :visible.sync="showTagDialog"
      append-to-body
      :close-on-click-modal="false"
      width="1000px"
      @close="onSetTagCancel"
    >
      <TagSelectForm
        :ids="tagIds"
        @cancel="onSetTagCancel"
        @confirm="onSetTagSuccess"
      />
    </el-dialog>
    <el-dialog
      title="通知"
      :visible.sync="messageDialog"
      append-to-body
      :close-on-click-modal="false"
      width="90%"
    >
      <Message />
    </el-dialog>
    <el-dialog
      title="新增提醒"
      :visible.sync="messageFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <MessageForm
        :id="currentRow.id"
        :name="currentRow.name"
        @success="messageFormSuccess"
        @cancel="messageFormCancel"
      />
    </el-dialog>
    <el-dialog
      title="通知提醒"
      :visible.sync="messageFormRemindDialog"
      append-to-body
      :close-on-click-modal="false"
      width="70%"
    >
      <MessageRemind @showdateil="onShowDetail" />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import { GetClientsList } from "./api";
import { GetClientMessagesList } from "./api/message";

import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import clientControl from "./mixins/clientControl";
import tableFilters from "./mixins/tableFilters";
import clientCloumn from "./mixins/clientCloumn";
import tableExport from "./mixins/tableExport";
import FilterSection from "./components/FilterSection";
import ClientSignForm from "./components/ClientSignForm";
import SetStageForm from "./components/SetStageForm";
import SetOwnerForm from "./components/SetOwnerForm";
import ClientFollowUpRecordListItem from "./components/ClientFollowUpRecordListItem";
import MultipleSetOwnerForm from "./components/MultipleSetOwnerForm";
import ImportByExcel from "./components/ImportByExcel";
import Message from "./components/Message";
import MessageForm from "./components/MessageForm";
import MessageRemind from "./components/MessageRemind";
import CheckClaim from "./components/CheckClaim";
import ClientReapetTable from "./components/ClientReapetTable";
import TagSelectForm from "@/views/client/clientTags/components/TagSelectForm";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
export default {
  components: {
    PageHeader,
    Message,
    MessageForm,
    CheckClaim,
    MessageRemind,
    BasicTable,
    FilterSection,
    ClientSignForm,
    ImportByExcel,
    SetStageForm,
    SetOwnerForm,
    MultipleSetOwnerForm,
    TagSelectForm,
    ClientReapetTable,
    ClientFollowUpRecordListItem,
  },
  mixins: [clientCloumn, clientControl, tableFilters, tableExport],
  data() {
    return {
      messageFormRemindDialog: false,
      messageFormDialog: false,
      messageDialog: false,
      tableLoading: false,
      toggleDisableLoading: false,
      toggleStartLoading: false,
      exporting: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      totalPages: 0,
      tableData: [],
      orderProp: "",
      orderDirection: "descending",

      currentId: "",
      currentRow: {},
      multipleSelected: [],
      tagIds: [],
    };
  },
  computed: {
    tempShowExport() {
      return this.$store.state.app.codeToExport;
    },
  },
  created() {
    this.buildTableColumns();
    this.page = 0;
    this.onRefresh();
  },
  watch: {
    $route: {
      handler() {
        this.init_insideParams();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    init_insideParams() {
      if (this.$route.name === "CientsList") {
        this.checkMessageRemindStatus();
        if (Object.keys(this.$route.params).length > 0) {
          for (const key in this.$route.params) {
            this.tableFilters[key] = this.$route.params[key];
          }
          this.page = 0;
          this.onRefresh();
        }
      }
    },

    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetClientsList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements, totalPages } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              ...this.getControlColumn(),
            };
          });
          this.total = totalElements;
          this.totalPages = totalPages;
          this.onResetPage();
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "";
      this.orderDirection = null;
      this.onRefresh();
    },
    onResetPage() {
      // 当前分页大于零，也大于总页数，并且如果总页数只有一页，那么page设置为零
      if (this.page > 0 && this.page > this.totalPages) {
        this.page = this.totalPage > 1 ? this.totalPages : 0;
      }
    },
    addRow() {
      this.$router.push({
        name: "ClientsForm",
        params: {
          id: null,
          type: "CLIENT",
          initForm: true,
        },
      });
    },
    onShowDetail(row) {
      this.messageDialog = false;
      this.messageFormRemindDialog = false;
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.id,
        },
        query: {
          name: row.name,
        },
      });
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onRefresh();
    },
    onImportSuccess() {
      this.page = 0;
      this.onRefresh();
    },
    handleShowMessage() {
      this.messageDialog = true;
    },
    handleMessageAdd(row) {
      this.currentRow = row;
      this.messageFormDialog = true;
    },
    messageFormSuccess() {
      this.currentRow = {};
      this.messageFormDialog = false;
    },
    messageFormCancel() {
      this.currentRow = {};
      this.messageFormDialog = false;
    },
    checkMessageRemindStatus() {
      GetClientMessagesList({
        page: 0,
        size: 2000,
        isRead: false,
        needRemind: true,
      }).then((res) => {
        if (res.data.content.length > 0) {
          this.messageFormRemindDialog = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>